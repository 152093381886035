.Expense {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 20px);
    padding-top: 30px; padding-bottom: 30px;
    margin-left: auto; margin-right: auto;

}

.NewExpense {
    text-align: left;
    padding: 30px;
    max-width: 700px;
    margin: 20px auto;
    width: calc(100% - 40px - 60px);
    
    .InputCell {
        margin-bottom: 10px;

        .Child:not(:first-child) {
            margin-left: 10px;
        }
    }

    margin-bottom: 20px;
}

label.Label {
    font-size: 0.6rem;
    color: rgb(100,100,100);
    font-weight: bold;
    margin-right:5px;
    white-space: nowrap;
}

.Section {
    text-align: left;
    width: 100%;
    padding-bottom: 20px;
    page-break-inside: avoid;
}

.Receipts{
    width: 100%;
    font-size: 0.9rem;
    text-align: left;
    .ReceiptCell {
        margin-top: 5px;
        max-width: calc(100% - 2px);
        max-height: 600px;
        margin-left:auto; margin-right: auto;
        object-fit: contain;
    }

    table {
        border-collapse: collapse;
        margin: auto;
        tr {
            border: 1px black solid;
            page-break-inside: avoid;

            td {
                border: 1px black solid;
                vertical-align: top;
                width: 50%;
                max-width: 400px;
                .Detail{
                    word-break: break-all;
                    height: 80px;
                    label.Label {
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}