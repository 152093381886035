body {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
}

.ArtSettlement {

    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px 0;

    .SettlementTarget, .ToggleButton {
        @media print {
            display: none;
        }
    }

    .ToggleButton {
        margin: 0 20px;
    }

    section {
        width: 100%;

        break-inside: avoid;
    
        .SectionHead {
            padding: 0 10px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        &.Header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-size: 0.8rem;
            line-height: 1.6rem;
            margin: 0 10px;
            width: calc(100% - 20px);

            .FromTo.Cell {
                text-align: left;
            }
            .When.Cell {
                text-align: right;

                .MuiOutlinedInput-root {
                    width: 120px;

                    font-size: 0.8rem;
                }
            }

        }

        &.Summary {
            font-size: 0.8rem;
            margin: 0 5px;

            .SummaryTable {
                width: calc(100% - 10px);
                max-width: 800px;
                margin: 0 auto;
                border-collapse: collapse;

                thead:not(:first-child):before {
                    line-height: 1rem; content: "\200C"; display: block;
                }

                tr {
                    border-left: 2px black solid;
                    border-right: 2px black solid;
                }

                .Header {
                    font-weight: bold;
                }

                .Data {
                    text-align: right;
                }

                .Colored.Header {
                    text-align: center;
                    background-color: rgb(217,225, 242);
                }

                .Colored.Data {
                    background-color: rgb(252, 228, 214);
                }

                .Minus.Data {
                    color: red;
                }

                th {
                    padding: 5px;
                    background-color: rgb(0, 112, 192);
                    color: white;
                    border-top: 2px black solid;
                    
                    &:last-child{
                        border-left: 2px black solid;
                    }
                }

                td {
                    padding: 5px;
                    text-align: left;
                    
                    &:last-child{
                        border-left: 2px black solid;
                    }
                }

                .FirstLine {
                    td {
                        border-top: 2px black solid;
                    }
                }
                .BottomLine {
                    td {
                        border-bottom: 2px black solid;
                    }
                }
            }

            .Byline {
                text-align: right;
                padding: 20px;
                font-size: 0.8rem;
            }
        }
    }
}

