
$SIGNATURE_COLOR: rgb(0, 176, 240);

.SettingStyle {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .GradientBackground {
        left: 0; top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background-image: linear-gradient(180deg, #{$SIGNATURE_COLOR} 0px, #{$SIGNATURE_COLOR} calc( ( 24px + 300px + 100% - 48px) / 3), transparent);
    };
    .SettingContainer {
        z-index: 1;
        width: calc(100% - 48px);
        max-width: calc(420px - 48px);
        padding:24px;
        .Section {
            padding-bottom: 100px;
            .SectionHeader {font-size: 1.8rem; font-weight: 550; padding-top: 10px; padding-bottom: 20px};
            .SectionBody {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                
                .BigWhiteLogo {
                    width: calc(100% / 2 - 48px / 2);
                    max-width: calc(420px / 2 - 48px / 2);
                };
                .CommonSetting {
                    .Row {
                        font-size: 0.8rem;
                        border-bottom: 0.5px lightgray solid;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                    };
                };
                .FAQ {
                    .Header {font-size: 1.1rem; font-weight: 500};
                    .Body {padding-left: 20px; padding-top: 20px; font-size: 0.8rem; text-align: left}
                }

                .StyledButton {
                    width: 210px;
                    margin: 5px;
                    background-color: white;
                    border-radius: 2px;
                }

                .EmailContainer {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        
        .KakaoNotice {
            word-break: keep-all;
            text-align: center;

            svg {
                padding: 0.05rem;
                border-radius: 0.45rem;
                font-size: 0.9rem;

                &.WithBG{
                    background-color: rgba(144,144,144,0.4);
                }
            }

            code {
                padding: 0.1rem;
                font-size: 0.9rem;
                background-color: white;
                color: red;
                font-family:Arial, Helvetica, sans-serif;
            }
        }
    }
}
