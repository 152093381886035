.EditableTableRoot {
    width: 100%;
    position: relative;
    overflow: auto;
    
    &::-webkit-scrollbar {
        all: initial;
        height: .8rem;
        width: .8rem;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: rgb(150,150,150);
        border-radius: 1rem;
    }

    table {
        display: relative;
        padding-left:10px;
        padding-right:10px;
        width: 100%;
        border-spacing: 0;

        thead {
            tr {
                color: #333333;
                background-color: #FFFFFF;

                th {
                    position: sticky;
                    top:0;
                    padding: 0;

                    .InnerContainer {
                        display: flex;
                        flex-direction: row;
                        padding:10px;
                        white-space: nowrap;
                        background-color: #FFFFFF;
                        align-items: center;
                        justify-content:  center;
                        line-height: 1rem;

                        .IconBtn {
                            margin-left:  0.2rem;
                            padding: 0.1rem;
                            width: 0.8rem;
                            height: 0.8rem;
                            background-color: rgba(33,33,33,0.8);
                            color: white;
                        }
                    }
                }
            }
        }
        tbody {
            td {
                padding-left: 10px; padding-right: 10px;
            }
        }
    }
}
@import url('/src/Component/Table/SlimEditableTable.scss');