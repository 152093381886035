.LoadingCircle {
    position: fixed;
    left: 0; top: 0;
    background-color: rgba(255,255,255,0);
    transition: 400ms;
    z-index: -1;

    &.Show {
        background-color: rgba(244,244,244,0.8);

        &.Animated {
            background-color: rgba(225,225,225,0.8);
        }
        z-index: 3;

        .Holder {
            opacity: 1;
        }
    }

    .Holder {
        --size: 16px;

        position: absolute;
        margin-left: calc(100vw / 2 - var(--size) / 2);
        margin-top: calc(100vh / 2 - var(--size) / 2);
        width: var(--size);
        height: var(--size);
        transition: 400ms;
        opacity: 0;

        &:not(.Center) .Circle {
            animation-duration: 3000ms;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-direction: normal;
            animation-fill-mode: none;
            animation-play-state: running;
            animation-name: swingUpSideDown;
        }

        .Circle {
            border-radius: var(--size);
            position: relative;
            width:100%;
            height: 100%;
            mask: url('https://static.webtoon.today/ddah/Circle.svg') no-repeat center;
        }

        &.Center .Circle { background-color: rgba(160, 190, 200, 1); }

        &.Bottom {transform: rotate(0deg);}
        &.Bottom .Circle { background-color: rgba(46, 80, 169, 1); }

        &.Left {transform: rotate(90deg);}
        &.Left .Circle { background-color: rgba(174, 49, 169, 1); }

        &.Top {transform: rotate(180deg);}
        &.Top .Circle { background-color: rgba(171, 88, 221, 1); }

        &.Right {transform: rotate(270deg);}
        &.Right .Circle { background-color: rgba(121, 159, 217, 1); }

    }
    --magnitude: 24px;

    @keyframes swingUpSideDown {    
         12.5%   { transform: translate(0, var(--magnitude) ) ; }
         25.0%   { transform: translate(0, 0); }                                25.1% { transform: rotate(180deg) translate(0, 0); }
         37.5%   { transform: rotate(180deg) translate(0, var(--magnitude) ); }
         50.0%   { transform: rotate(180deg) translate(0, 0); }                 50.1% { transform: rotate(  0deg) translate(0,0);}
         62.5%   { transform: rotate(  0deg) translate(0, var(--magnitude) ) ; }
         75.0%   { transform: rotate(180deg) translate(0, var(--magnitude)); }
         87.5%   { transform: rotate(360deg) translate(0, var(--magnitude)); }
        100.0%   { transform: rotate(360deg) translate(0, 0); }
    }

}