


.SendEmailInner{
    border: 1px solid lightgray;
    border-radius: 4px;

    @media (max-width: 720px) {
        width: calc( 100% - 40px );
        max-width: 640px;
    }
    
    .EmailPreview{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 20px;
        background-color: rgb(255,255,255);

        div{
            max-width: 100%;
        }
    }

    .SendButton{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
    }
}