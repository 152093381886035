.Profile {
    all: initial;
    box-align: center;
    display: flex;
    flex-direction: column;
    position: relative;
    .RowContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .Image {
            width: 150px;
            height: 150px;
            img {width: 150px; height: 150px; border-radius: 75px; object-fit: contain;}
            input {
                width: 0;
                height: 0;
            }
        };
        .Text {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            padding-left: 1rem;
            padding-right: 1rem;
            font-weight: bold;
        };
    };
    .EditButton {
        position: absolute;
        width: 1rem;
        height: 1rem;
    }
}