
.EditableTableRoot.Slim{

    table thead tr th .InnerContainer {
        background-color: inherit;
        padding: 5px 0;
    }

    table {
        thead {
            font-size: 0.8rem;
    
            th {
                background-color: rgb(160,160,160);
                color: white;
    
                button.IconBtn {
                    display: none;
                }
                
                &:first-child {
                    border-left: 1px black solid;
                }
                border-right: 1px black solid;
                border-top: 1px black solid;
            }
        }
        tbody {
            word-break: keep-all;
            text-align: right;
            font-size: 0.8rem;
            line-height: 1.5rem;
    
            tr:first-child {
                td {
                    border-top: 1px black solid;
                }
            }
    
            td {
                &:first-child {
                    border-left: 1px black solid;
                }
                border-right: 1px black solid;
                border-bottom: 1px black solid;
    
                padding-left: 5px; padding-right: 5px;
            }
        }
    }
}