

.Router {
    width: 100%;
    overflow-x: hidden;
    position: relative;
    
    @media (max-width: 700px) {
        min-height: calc(100vh - 110px);
    }

    @media (min-width: 701px) {
        min-height: calc(100vh - 65px);
    }
}