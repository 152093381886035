.Sales {
    width: 100%;
    max-width: 800px;
    margin-left: auto; margin-right: auto;
    

}

div[role="dialog"] {
    max-width: inherit;
    
    th legend {
        background-color: white;
        overflow: visible;
        max-width: none;
    
        span {
            opacity: 1;
        }
    }
}