.Table {
    display: table;
    width: 100%;

    .TableRow {
        display: table-row;
        width: 100%;

        .TableCell {
            display: table-cell;
            height: 32px;

            &.HeaderCell {
                background-color: gray;
                vertical-align: middle;
                text-align: center;

                .Label {
                    color: white;
                    font-size: 0.9rem;
                    line-height: 0.9rem;
                }
            }

            &.DataCell {
                text-align: right;
                box-sizing: border-box;
                padding-left: 10px; padding-right: 10px;
                border-right: 1px lightgray solid;
                border-bottom: 1px lightgray solid;
                vertical-align: middle;

                font-size: 0.8rem;
            }

            &.QuarterCell {
                box-sizing: border-box;
                width: 25%;
            }
        }
    }
}